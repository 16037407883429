import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, NavItem, Image, Dropdown, Form, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { NavLink, useHistory } from 'react-router-dom';
import useDebounce from './use-debounce';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { NavBar } from './styles';
import { SearchBar } from '../../pages/Home/elements/styles';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsConditions from '../TermsConditions';
import OptOutModal from '../OptOutModal';
import { useStore } from '../../store';
import connect from '../../assets/icons/carticon.svg';
import logo from '../../assets/images/121Logo.svg';
import search from '../../assets/icons/search.svg';
import suggest from '../../assets/icons/recent.svg';
// import pointsicon from "../../assets/icons/pointshistory.svg";
// import points from "../../assets/images/sidepoints.svg";
import check from '../../assets/icons/check.svg';
import UserHolder from '../../assets/images/profile-holder.svg';

const Navigation = observer(() => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  // const [active, setActive] = useState("notactive");
  const [showOut, setOptOut] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [history, setHistory] = useState(false);
  // const [searchTerm, setSearchTerm] = useState('');

  const { searchStore, accountStore, authStore, meetingStore } = useStore();

  const {
    setKey,

    listSuggestResults,
    setSuggestResults,
    previousSearches,
    listPreviousSearches,
    listInterests,

    listSearchinRoles,
    listIndustries,
    industries,
    clearTypeFilters,
    clearAllFilters,
  } = searchStore;

  const { user, listRoles, footer, listFooter } = accountStore;
  const { onLogOut } = authStore;
  const { fetchCart, cart, recentUser, selectedProduct } = meetingStore;

  const location = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const queryKey = query.get('q');
  const colors = ['#05fa98', '#05facd'];
  useEffect(() => {
    listInterests();
  }, [listInterests]);

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  useEffect(() => {
    listRoles();
  }, [listRoles]);

  useEffect(() => {
    listSearchinRoles();
  }, [listSearchinRoles]);

  useEffect(() => {
    listIndustries();
  }, [listIndustries]);

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    clearTypeFilters();
    clearAllFilters();
    setKey(data.key);

    location.push(`/search?q=${data.key}`);
    setSuggestResults([]);
  };

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const suggestResults = (key) => {
    setSuggestResults([]);
    setSearchValue(key);
    setSearchDropdown(true);

    if (key.length < 1) {
      setHistory(true);
    } else {
      setHistory(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) listSuggestResults(debouncedSearchTerm);
  }, [debouncedSearchTerm, listSuggestResults]);

  const suggestSearch = (key, searchtype) => {
    clearAllFilters();
    setKey(key);
    setValue('key', key);
    if (searchtype === '') {
      location.push(`/search?q=${key}`);
    } else {
      location.push(`/search?q=${encodeURIComponent(key)}&in=${searchtype}`);
    }
    setSearchDropdown(false);
  };

  const logOut = () => {
    onLogOut();
    location.push('/');
  };


  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  useEffect(() => {
    listPreviousSearches();
  }, [listPreviousSearches]);

  // useEffect(() => {
  //   window.addEventListener("scroll", (e) => {
  //     if (window.scrollY > 50) {
  //       setActive("activetab");
  //     } else {
  //       setActive("notactive");
  //     }
  //   });
  // });

  const refSearch = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refSearch &&
        refSearch.current &&
        !refSearch.current.contains(event.target)
      ) {
        setSearchDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
  }, [refSearch, setSearchDropdown]);

  return (
    <NavBar className='d-none d-lg-block sticky-top'>
      <nav className='navbar navbar-expand-md  tabs' role='navigation'>
        <div className='container-fluid top-content'>
          <a className='navbar-brand' href='/'>
            <Image src={logo} alt='logo' />
          </a>
          {location.location.pathname !== '/' && (
            <div className='search'>
              <div className='search-initiator d-flex'>
                Discover By{' '}
                <Dropdown>
                  <Dropdown.Toggle
                    variant='basic'
                    id='more'
                    className='a_selectSidebar d-flex align-items-center'
                  >
                    Industry
                    <i className='droparrow' />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align='bottom'>
                    <i className='arrow d-none d-lg-block' />

                    <div className='d-lg-flex'>
                      <div>
                        {industries
                          ?.filter((item) => item.industryl1 !== 'OTHERS')
                          .slice(0, Math.ceil((industries?.length - 1) / 2))
                          .map((item) => (
                            <Dropdown.Item
                              as='button'
                              key={item.id}
                              onClick={() => {
                                // setSearchIn('industry');
                                suggestSearch(item.industryl1, 'industry');
                              }}
                            >
                              {item.industryl1}
                            </Dropdown.Item>
                          ))}
                      </div>
                      <div>
                        {industries
                          ?.filter((item) => item.industryl1 !== 'OTHERS')
                          .slice(Math.ceil((industries?.length - 1) / 2))
                          .map((item) => (
                            <Dropdown.Item
                              as='button'
                              key={item.id}
                              onClick={() => {
                                // setSearchIn('industry');
                                suggestSearch(item.industryl1, 'industry');
                              }}
                            >
                              {item.industryl1}
                            </Dropdown.Item>
                          ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <SearchBar
                onSubmit={handleSubmit(onSubmit)}
                onChange={(e) => {
                  // setSearchTerm(e.target.value);
                  setKey(e.target.value);
                  // suggestResults(e.target.value);
                }}
                onBlur={() =>
                  setTimeout(() => {
                    setSuggestResults([]);
                  }, 500)
                }
                onFocus={(e) => suggestResults(e.target.value)}
                ref={refSearch}
              >
                <Form.Control
                  type='search'
                  id='search-tablet-top'
                  data-testid='search-input-tablet'
                  className='form-control '
                  placeholder='Members'
                  aria-label='Search'
                  defaultValue={queryKey}
                  aria-describedby='basic-addon1'
                  autoComplete='off'
                  name='key'
                  {...register('key', { required: true })}
                />

                <button className='search-button' type='submit'>
                  <Image src={search} className='search-icon' />
                </button>
                {searchDropdown && history && (
                  <div className='suggested'>
                    <div className='search-head'>Recent searches</div>
                    {previousSearches?.map((item) => (
                      <div
                        key={item.keyword}
                        className='search-chip d-flex'
                        onClick={() => suggestSearch(item.keyword, '')}
                      >
                        <Image src={suggest} />
                        <div className='key'>{item.keyword}</div>
                      </div>
                    ))}
                  </div>
                )
                // (
                //   suggestedResults?.length > 0 && (
                //     <div className='suggested'>
                //       {suggestedResults?.map((item) => (
                //         <div
                //           key={item.id}
                //           className='suggest-card'
                //         >
                //           <div className='suggest-name'>
                //             {getHighlightedText(item.full_name)}
                //           </div>
                //           <div className='suggest-info'>{item.title},</div>
                //           <div
                //             className='suggest-info'
                //           >
                //             {getHighlightedText(item.company_name)}
                //           </div>
                //         </div>
                //       ))}{' '}
                //       <p
                //         className='seeAll'
                //         onClick={() => suggestSearch(searchTerm, '')}
                //       >
                //         See all
                //       </p>
                //     </div>
                //   )
                // )
              }
              </SearchBar>
              

              {/* <SuggestChips className="d-flex chips">
                <Image src={suggest} />
                {previousSearches?.map((item) => (
                  <div
                    key={item.keyword}
                    className="search-chip"
                    onClick={() => suggestSearch(item.keyword)}
                  >
                    {item.keyword}
                  </div>
                ))}
              </SuggestChips> */}
            </div>
          )}
          <div className='d-flex'>
            <Nav className='ml-auto'>
              <NavItem>
                <NavLink exact to='/' className='nav-link'>
                  Home
                </NavLink>
              </NavItem>
              {/* {user.user_type === 0 && (
              <NavItem>
                <NavLink to="/projects" className="nav-link">
                  Projects
                </NavLink>
              </NavItem>
            )} */}
              <NavItem>
                <NavLink to='/my-meetings' className='nav-link'>
                  Meetings
                </NavLink>
              </NavItem>
              {/* {user.user_type === 0 ? ( */}
              <NavItem>
                <NavLink to='/my-points' className='nav-link'>
                  Points
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink exact to="/meetings" className="nav-link">
                  Add Points
                </NavLink>
              </NavItem> */}

              <div className='cart-div'>
                <button className='cart' onClick={() => location.push('/cart')}>
                  {cart?.members_details?.length > 0 && (
                    <div className='cart-count'>
                      {cart.members_details.length}
                    </div>
                  )}
                  <Image className='cart-icon' src={connect} />
                </button>
                {recentUser && (
                  <div className='alert d-flex align-items-center'>
                    <i className='alert-arrow' />
                    <div className='success-circle'>
                      <Image src={check} id='checkCircle' />
                    </div>
                    Added
                    <span>
                      {recentUser?.full_name
                        ? recentUser.full_name
                        : `${recentUser.first_name} ${recentUser.last_name}`}
                    </span>{' '}
                    to your next {selectedProduct?.name} meeting
                  </div>
                )}
              </div>

              {/* <Dropdown>
              <Dropdown.Toggle variant="basic" id="more" className="a_select">
                More
              </Dropdown.Toggle>

              <Dropdown.Menu>
               
              </Dropdown.Menu>
            </Dropdown> */}
            </Nav>

            <Dropdown>
              <Dropdown.Toggle
                variant='basic'
                id='more'
                className='a_selectSidebar profile-pic'
              >
                {user?.photo ? (
                  <Image
                    src={user?.photo ? user.photo : UserHolder}
                    roundedCircle
                  />
                ) : (
                  <div
                    className='userpic d-flex justify-content-center align-items-center'
                    // style={{
                    //   background: `#${Math.floor(
                    //     Math.random() * 16777215
                    //   ).toString(16)}50`,
                    // }}
                    style={{
                      background: `${
                        colors[Math.floor(Math.random() * colors.length)]
                      }50`,
                    }}
                  >
                    {user?.last_name
                      ? `${user?.first_name?.split('')[0]}${
                          user?.last_name?.split('')[0]
                        }`
                      : `${user?.first_name?.split('')[0]} ${
                          user?.first_name?.split('')[1]
                        }`}
                  </div>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu align='right'>
                <i className='menu-arrow' />
                <h4>
                  {user.first_name} {user.last_name}
                </h4>
                <div className='designation'>{user.title}</div>
                <div className='designation'>{user.company?.name}</div>
                <div className='designation'>{user.email}</div>
                <Button
                  className='btn-default'
                  onClick={() => location.push('/profile')}
                >
                  My Profile
                </Button>

                <div className='btn-actions top d-flex'>
                  <div>
                    <button onClick={() => logOut()}>Logout</button>
                  </div>

                  {footer.map((item) => (
                    <div className='button-group'>
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        <button className='link' id='terms'>
                          {item.text}
                        </button>
                      </a>
                    </div>
                  ))}
                </div>

                <div className='consent'>
                  {user.consent_date && (
                    <>
                      Accepted on:{' '}
                      <Moment format='DD MMMM, YYYY'>
                        {user.consent_date}
                      </Moment>
                    </>
                  )}{' '}
                  <button
                    className='sidebutton'
                    onClick={() => setOptOut(true)}
                  >
                    Opt-out
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>
      <OptOutModal show={showOut} setEdit={setOptOut} />
      <TermsConditions show={showTerms} setShow={setShowTerms} />
      <PrivacyPolicy show={showPrivacy} setShow={setShowPrivacy} />
    </NavBar>
  );
});

export default Navigation;
