import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Image } from "react-bootstrap";
import { FilterModal } from "../elements/styles";
import IndustryFilter from "./IndustryFilter";
import { useStore } from "../../../store";
import CityFilter from "./CityFilter";
import ProfileFilter from "./ProfileFilter";
// import Selected from "./Selected";
// import tranges from "../../../assets/data/range.json";
// import hranges from "../../../assets/data/hrange.json";
import filter from "../../../assets/icons/Filter.svg";
import down from "../../../assets/icons/dropdown-arrow.svg";
import up from "../../../assets/icons/dropup-arrow.svg";

const DesktopSearchFilter = observer(() => {
  const [open, setOpen] = useState(false);
  const [openTrange, setOpenTrange] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  const [openHrange, setOpenHrange] = useState(false);
  // const [openInternet, setOpenInternet] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  // const manuList = [
  //   { label: "Manufacturing", value: "Manufacturing" },
  //   { label: "Services", value: "Services" },
  //   { label: "Both", value: "Both" },
  // ];

  // const internetList = [
  //   { label: "BRICK-MORTAR", value: "BRICK-MORTAR" },
  //   { label: "INTERNET", value: "INTERNET" },
  //   { label: "BOTH", value: "BOTH" },
  // ];

  const { searchStore } = useStore();
  const {
    rangeChecked,
    onTrangeListChanged,
    listSearchResults,
    selectedHRangeList,
    onHrangeListChanged,
    hranges,
    tranges,
    profiles,
    industries,
    cities,
  } = searchStore;

  const handleRangeChange = (e) => {
    onTrangeListChanged(e.target);
    listSearchResults();
  };

  // const handleRegionChange = (e) => {
  //   onRegionListChange(e.target);
  //   listSearchResults();
  // };

  return (
    <FilterModal className="d-none d-lg-block col-lg-3">
      <div className="d-flex  justify-content-between header">
        <div className="filter-icon">
          <Image src={filter} />
          Filter
        </div>
      </div>
      <div>
          <div>
      { profiles.length >1 ?
      <div className="tab-content">
              
                <div className="d-flex justify-content-between filter-header">
                  Profile
                  <button
                    onClick={() => setOpenProfile(!openProfile)}
                    className="drop"
                  >
                    <Image src={openProfile ? up : down} />
                  </button>
                </div>
                
                {openProfile && (
                  <div className="desk-filter">
                    <ProfileFilter desktop={true} />
                  </div>
                )}
              </div>
          :
                 <></>
              } 
          {industries.length >1 ?
          <div className="tab-content">
            <div className="d-flex justify-content-between filter-header">
              Industry
              <button onClick={() => setOpen(!open)} className="drop">
                <Image src={open ? up : down} />
              </button>
            </div>
            {open && (
              <div className="desk-filter">
                <IndustryFilter desktop={true} />
              </div>
            )}
          </div>
          :
                 <></>
            }

          {tranges.length >0 ? 
          <div className="tab-content">
            <div className="d-flex justify-content-between filter-header">
              Turnover range
              <button
                onClick={() => setOpenTrange(!openTrange)}
                className="drop"
              >
                <Image src={openTrange ? up : down} />
              </button>
            </div>
            {openTrange && (
              <div className="desk-filter">
                {tranges?.map((item) => (
                  <Form.Check
                    label={item.display_name}
                    value={item.name}
                    key={item.id}
                    checked={rangeChecked.includes(item.name)}
                    onChange={handleRangeChange}
                  />
                ))}
              </div>
            )}
      </div>
        :<></>
        }

      {hranges.length >0 ? 
      <div className="tab-content">
                <div className="d-flex justify-content-between filter-header">
                  Headcount range
                  <button
                    onClick={() => setOpenHrange(!openHrange)}
                    className="drop"
                  >
                    <Image src={openHrange ? up : down} />
                  </button>
                </div>
                {openHrange && (
                  <div className="desk-filter">
                    {hranges?.map((item) => (
                      <Form.Check
                        label={item.name}
                        value={item.name}
                        key={item.id}
                        checked={selectedHRangeList.includes(item.name)}
                        onChange={(e) => {
                          onHrangeListChanged(e.target);
                          listSearchResults();
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
      :<></>
      }

      {cities.length >1 ? 
              <div className="tab-content">
                <div className="d-flex justify-content-between filter-header">
                  City-Region
                  <button
                    onClick={() => setOpenCity(!openCity)}
                    className="drop"
                  >
                    <Image src={openCity ? up : down} />
                  </button>
                </div>
                {openCity && (
                  <div className="desk-filter">
                    <CityFilter desktop={true} />
                  </div>
                )}
              </div>
      :<></> }
              {/* <div className="tab-content">
                <div className="d-flex justify-content-between filter-header">
                  Internet / Brick-Mortar
                  <button
                    onClick={() => setOpenInternet(!openInternet)}
                    className="drop"
                  >
                    <Image src={openInternet ? up : down} />
                  </button>
                </div>
                {openInternet && (
                  <div className="desk-filter">
                    {internetList?.map((item) => (
                      <Form.Check
                        label={item.label}
                        value={item.value}
                        key={item.value}
                        type={"radio"}
                        checked={selectedInterList.includes(item.value)}
                        onChange={(e) => {
                          onInterListChanged(e.target, item.label);
                          listSearchResults();
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>  */}

              
        </div>
      </div>
    </FilterModal>
  );
});

export default DesktopSearchFilter;
