import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Image, Modal, Button, Spinner, Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import { useStore } from '../../store';
import useDebounce from '../../components/TopBar/use-debounce';
import { SearchBar, SearchDiv } from './elements/styles';
import BottomBar from '../../components/BottomBar';
import Selected from './components/Selected';
import DMCard from '../../components/DMCard';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import SwitchCart from '../../components/SwitchCart';
import DesktopSearchFilter from './components/DesktopSearchFilter';
import MobileTopBar from '../../components/MobileTopBar';
import SearchFilter from './components/SearchFilter.js';
import bgDesign from '../../assets/images/back-design.svg';
import search from '../../assets/icons/search.svg';
import sort from '../../assets/icons/sortarrow.svg';
import filter from '../../assets/icons/Filter2.svg';
import wave from '../../assets/images/wave1.svg';
import suggest from '../../assets/icons/recent.svg';
import downfilter from '../../assets/icons/drop-filter.svg';
import down from '../../assets/icons/dropdown-arrow.svg';
import smWave from '../../assets/images/smwave.svg';
import downSm from '../../assets/icons/meetingdown.svg';
import srchistory from '../../history';

const Search = observer(() => {
  const [history, setHistory] = useState(false);
  const [show, setShow] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);
  const [switchProduct, setSwitchProduct] = useState();
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [active, setActive] = useState(true);

  const location = useHistory();
  const { searchStore, meetingStore } = useStore();

  const {
    listSearchResults,
    setKey,
    searchResults,
    listCities,
    searchResultsLoading,
    listHRanges,
    listTRanges,
    listRoles,
    listProfiles,
    listIndustries,
    listSuggestResults,
    setSuggestResults,
    previousSearches,
    setSortBy,
    sortBy,
    selectedRegionList,
    industryL2List,
    selectedHRangeList,
    selectedManuList,
    selectedInterObject,
    selectedCitiesList,
    roleL2List,
    profileL1List,
    profileL2List,
    industryL1List,
    selectedInterestList,
    rangeChecked,
    roleL1List,
    setSearchIn,
    interests,
    industries,
    listSearchinRoles,
    clearTypeFilters,
    key,
    clearAllFilters,
    searchResultCount,
    paginateSearchResults,
    paginateLoading,
  } = searchStore;

  const { products, listProducts, selectedProduct } = meetingStore;

  clearTypeFilters();
  const query = new URLSearchParams(useLocation().search);
  const queryKey = query.get('q');
  const queryIn = query.get('in');

  if (queryIn === 'industry') {
    setSearchIn('industry');
  }
  if (queryIn === 'role') {
    setSearchIn('role');
  }

  if (queryIn === 'interest') {
    setSearchIn('interest');
  }

  useEffect(() => {
    setKey(queryKey);
  }, [queryKey, setKey]);

  useEffect(() => {
    listProducts();
  }, [listProducts]);

  useEffect(() => {
    listSearchResults();
  }, [queryKey, listSearchResults]);

  useEffect(() => {
    listCities(true);
  }, [listCities]);

  useEffect(() => {
    listSearchinRoles();
  }, [listSearchinRoles]);

  useEffect(() => {
    listIndustries();
  }, [listIndustries]);

  useEffect(() => {
    listHRanges();
  }, [listHRanges]);

  useEffect(() => {
    listTRanges();
  }, [listTRanges]);

  useEffect(() => {
    listRoles();
  }, [listRoles]);

  useEffect(() => {
    listProfiles();
  }, [listProfiles]);

  const scrollUsers = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (
      Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight) + 2 &&
      paginateLoading === false
    ) {
      // listpaginatedview();
      paginateSearchResults();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY > 150) {
        setActive(false);
      } else {
        setActive(true);
      }
    });
  });

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = () => {
    clearTypeFilters();
    clearAllFilters();
    srchistory.push(`/search?q=${key}`);
    listSearchResults();
    setSuggestResults([]);
  };

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const suggestResults = (key) => {
    setSuggestResults([]);
    setSearchValue(key);
    setSearchDropdown(true);

    if (key.length < 1) {
      setHistory(true);
    } else {
      setHistory(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) listSuggestResults(debouncedSearchTerm);
  }, [debouncedSearchTerm, listSuggestResults]);

  const refSearch = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refSearch &&
        refSearch.current &&
        !refSearch.current.contains(event.target)
      ) {
        setSearchDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
  }, [refSearch, setSearchDropdown]);


  const suggestSearch = (key, searchtype) => {
    clearAllFilters();
    setKey(key);
    setValue('key', key);
    if (searchtype === '') {
      location.push(`/search?q=${key}`);
    } else {
      location.push(`/search?q=${encodeURIComponent(key)}&in=${searchtype}`);
    }
  };

  return (
    <>
      <Helmet title={`${queryKey} | 121connects`}>
        <meta
          id='meta-description'
          name='description'
          content={`Find CIOs, CISOs and top enterprise IT Leaders from India interested in ${queryKey}`}
        />
      </Helmet>
      <Navigation />
      <SearchDiv>
        <div className='glass '>
          <Image src={smWave} className='d-lg-none sm-wave' />
          <MobileTopBar />
          <div className='glass-design d-none d-lg-block'></div>

          <div className='d-lg-none search' id='searchbar'>
          <div className='search-initiator d-flex'>
          Discover By{' '}
              <Dropdown>
                <Dropdown.Toggle
                  variant='basic'
                  id='more'
                  className='a_selectSidebar d-flex align-items-center'
                >
                  Industry
                  <i className='droparrow' />
                </Dropdown.Toggle>

                <Dropdown.Menu align='bottom'>
                  <i className='arrow d-none d-lg-block' />

                  <div className='d-lg-flex'>
                    <div>
                      {industries
                        ?.filter((item) => item.industryl1 !== 'OTHERS')
                        ?.slice(0, Math.ceil(interests?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('industry');
                              suggestSearch(item.industryl1, 'industry');
                            }}
                          >
                            {item.industryl1}
                          </Dropdown.Item>
                        ))}
                    </div>
                    <div>
                      {industries
                        ?.filter((item) => item.industryl1 !== 'OTHERS')
                        ?.slice(Math.ceil(industries?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('industry');
                              suggestSearch(item.industryl1, 'industry');
                            }}
                          >
                            {item.industryl1}
                          </Dropdown.Item>
                        ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <SearchBar
              onSubmit={handleSubmit(onSubmit)}
              onChange={(e) => {
                // setSearchTerm(e.target.value);

                setKey(e.target.value);
                // suggestResults(e.target.value);
              }}
              onBlur={() =>
                setTimeout(() => {
                  setSuggestResults([]);
                }, 500)
              }
              onFocus={(e) => suggestResults(e.target.value)}
              ref={refSearch}
            >
              <Form.Control
                type='search'
                id='search-tablet'
                data-testid='search-input-tablet'
                className='form-control '
                placeholder='Members'
                defaultValue={queryKey}
                aria-label='Search'
                aria-describedby='basic-addon1'
                autoComplete='off'
                name='key'
                {...register('key', { required: true })}
              />

              <button className='search-button' type='submit'>
                <Image src={search} />
              </button>
              {active &&
                (searchDropdown && history && (
                  <div className='suggested'>
                    <div className='search-head'>Recent searches</div>
                    {previousSearches?.map((item) => (
                      <div
                        key={item.keyword}
                        className='search-chip d-flex'
                        onClick={() => suggestSearch(item.keyword, '')}
                      >
                        <Image src={suggest} />
                        <div className='key'>{item.keyword}</div>
                      </div>
                    ))}
                  </div>
                ))}
            </SearchBar>
          </div>
          {/* {previousSearches.length ? (
            <SuggestChips className="d-flex chips d-lg-none">
              <Image src={suggest} />
              {previousSearches?.map((item) => (
                <div
                  key={item.keyword}
                  className="search-chip"
                  onClick={() => suggestSearch(item.keyword)}
                >
                  {item.keyword}
                </div>
              ))}
            </SuggestChips>
          ) : (
            <></>
          )} */}

          <div className='content'>
            <div className='d-flex justify-content-between filter-bar d-lg-none'>
              {/* <div className="d-flex">
                <Form.Check
                  label="Members"
                  value="user"
                  checked={typeFilters.includes("user")}
                  onChange={(e) => setTypeFilters(e.target)}
                />
                <Form.Check
                  label="Projects"
                  value="project"
                  checked={typeFilters.includes("project")}
                  onChange={(e) => setTypeFilters(e.target)}
                />
                <Form.Check
                  label="Companies"
                  value="company"
                  checked={typeFilters.includes("company")}
                  onChange={(e) => setTypeFilters(e.target)}
                />
              </div> */}
              <div className='d-flex'>
                <Button
                  onClick={() => setShow(true)}
                  className='d-lg-none filter-button'
                >
                  <Image src={filter} /> Filter <Image src={downfilter} />
                </Button>
                <div className='filter-count'>
                  {selectedRegionList?.length +
                    industryL2List?.length +
                    selectedHRangeList?.length +
                    selectedManuList?.length +
                    selectedInterObject?.length +
                    selectedCitiesList?.length +
                    roleL2List?.length +
                    profileL1List?.length +
                    profileL2List?.length +
                    industryL1List?.length +
                    selectedInterestList?.length +
                    rangeChecked?.length +
                    roleL1List?.length}{' '}
                  applied
                </div>
              </div>
              <div className='meeting-type d-flex align-items-center'>
                <>
                  Choose meeting type{' '}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='basic'
                      id='more'
                      className='a_selectSidebar d-flex align-items-center'
                    >
                      {selectedProduct?.name}
                      <Image src={downSm} />
                    </Dropdown.Toggle>
                    {products.length > 1 ? (
                      <Dropdown.Menu align='right'>
                        {products.map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              setSwitchProduct(item);
                              setShowSwitch(true);
                            }}
                            active={item.id === selectedProduct.id}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    ) : (
                      ''
                    )}
                  </Dropdown>
                </>
              </div>
            </div>
            <SwitchCart
              setShow={setShowSwitch}
              show={showSwitch}
              product={switchProduct}
            />

            <div className='content-holder'>
              <DesktopSearchFilter />
              <div className='col-lg-7 position-relative left-content-holder'>
                <Selected />
                <div className='cards'>
                  <Image
                    src={wave}
                    alt='bg-design'
                    className='wave-design d-none d-lg-block'
                  />
                  {searchResultsLoading ? (
                    <div
                      data-testid='app-loader'
                      className='d-flex justify-content-center align-items-center'
                    >
                      <Spinner animation='grow' size='lg' />
                    </div>
                  ) : searchResults?.length ? (
                    <>
                      <div className='d-flex search-line align-items-center'>
                        <div className='result-count'>
                          {searchResultCount} results
                        </div>
                        <div className='d-flex align-items-center sort-div'>
                          Sort by
                          <button
                            onClick={() =>
                              setSortBy(
                                sortBy !== 'user_asc' ? 'user_asc' : 'user_desc'
                              )
                            }
                            className={`${
                              sortBy === 'user_asc' ? '' : 'transform'
                            }
                            ${sortBy.startsWith('user') ? 'active' : ''}`}
                          >
                            Members <Image src={sort} />
                          </button>
                          <button
                            onClick={() =>
                              setSortBy(
                                sortBy !== 'company_asc'
                                  ? 'company_asc'
                                  : 'company_desc'
                              )
                            }
                            className={`${
                              sortBy === 'company_asc' ? '' : 'transform'
                            } ${sortBy.startsWith('company') ? 'active' : ''}`}
                          >
                            Company <Image src={sort} />
                          </button>
                        </div>
                        <div className='flex-column-meeting meeting-type d-none d-lg-flex align-items-center'>
                          <>
                            Choose meeting type{' '}
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='basic'
                                id='more'
                                className='a_selectSidebar d-flex align-items-center'
                              >
                                {selectedProduct?.name}
                                <Image src={down} className='down' />
                              </Dropdown.Toggle>
                              {products.length > 1 ? (
                                <Dropdown.Menu align='right'>
                                  {products.map((item) => (
                                    <Dropdown.Item
                                      as='button'
                                      key={item.id}
                                      onClick={() => {
                                        setSwitchProduct(item);
                                        setShowSwitch(true);
                                      }}
                                      active={item.id === selectedProduct.id}
                                    >
                                      {item.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              ) : (
                                ''
                              )}
                            </Dropdown>
                          </>
                        </div>
                      </div>

                      <div className='dm-cards' onScroll={scrollUsers}>
                        {searchResults?.map(
                          (result) => (
                            // return (
                            <DMCard dm={result} key={'user' + result.id} />
                          )
                          // );
                        )}
                        {paginateLoading && (
                          <div
                            data-testid='app-loader'
                            style={{
                              position: 'absolute',
                              bottom: '29px',
                              right: '50%',
                            }}
                            className='d-flex justify-content-center align-items-center p-2'
                          >
                            <Spinner animation='grow' size='md' />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className='empty-results'>
                      <div className='col-8 '>
                        <h3>No matching results found</h3>
                        <div className='d-flex align-items-end'>
                          <Skeleton circle={true} width={50} height={50} />
                          <div className='d-flex flex-column skeletons '>
                            <Skeleton height={13} />
                            <Skeleton height={8} width={150} />
                          </div>
                        </div>
                        {/* <div className='search-initiator d-lg-flex'>
                          Find by{' '}
                          <div className='d-flex flex-wrap'>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='basic'
                                id='more'
                                className='a_selectSidebar d-flex align-items-center'
                              >
                                Interests
                                <i className='droparrow' />
                              </Dropdown.Toggle>

                              <Dropdown.Menu align='bottom'>
                                <i className='arrow d-none d-lg-block' />

                                <div className='d-lg-flex'>
                                  <div>
                                    {interests
                                      ?.slice(
                                        0,
                                        Math.ceil(interests?.length / 2)
                                      )
                                      .map((item) => (
                                        <Dropdown.Item
                                          as='button'
                                          key={item.id}
                                          onClick={() => {
                                            // setSearchIn('interest');
                                            suggestSearch(item.name,'interest');
                                          }}
                                        >
                                          {item.name}
                                        </Dropdown.Item>
                                      ))}
                                  </div>
                                  <div>
                                    {interests
                                      ?.slice(Math.ceil(interests?.length / 2))
                                      .map((item) => (
                                        <Dropdown.Item
                                          as='button'
                                          key={item.id}
                                          onClick={() => {
                                            // setSearchIn('interest');
                                            suggestSearch(item.name,'interest');
                                          }}
                                        >
                                          {item.name}
                                        </Dropdown.Item>
                                      ))}
                                  </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='basic'
                                id='more'
                                className='a_selectSidebar d-flex align-items-center'
                              >
                                Responsibility
                                <i className='droparrow' />
                              </Dropdown.Toggle>

                              <Dropdown.Menu align='bottom'>
                                <i className='arrow' />

                                <div className='d-lg-flex'>
                                  <div>
                                    {searchinRoles
                                      ?.slice(
                                        0,
                                        Math.ceil(searchinRoles?.length / 2)
                                      )
                                      .map((item) => (
                                        <Dropdown.Item
                                          as='button'
                                          key={item.id}
                                          onClick={() => {
                                            // setSearchIn('role');
                                            suggestSearch(item.rolel2,'role');
                                          }}
                                        >
                                          {item.rolel2}
                                        </Dropdown.Item>
                                      ))}
                                  </div>
                                  <div>
                                    {searchinRoles
                                      ?.slice(
                                        Math.ceil(searchinRoles?.length / 2)
                                      )
                                      .map((item) => (
                                        <Dropdown.Item
                                          as='button'
                                          key={item.id}
                                          onClick={() => {
                                            // setSearchIn('role');
                                            suggestSearch(item.rolel2,'role');
                                          }}
                                        >
                                          {item.rolel2}
                                        </Dropdown.Item>
                                      ))}
                                  </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='basic'
                                id='more'
                                className='a_selectSidebar d-flex align-items-center'
                              >
                                Industry
                                <i className='droparrow' />
                              </Dropdown.Toggle>

                              <Dropdown.Menu align='bottom'>
                                <i className='arrow d-none d-lg-block' />

                                <div className='d-lg-flex'>
                                <div>
                      {industries
                        ?.filter((item) => item.industryl1 !== 'OTHERS')
                        ?.slice(0, Math.ceil(interests?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('industry');
                              suggestSearch(item.industryl1,'industry');
                            }}
                          >
                            {item.industryl1}
                          </Dropdown.Item>
                        ))}
                    </div>
                    <div>
                      {industries
                        ?.filter((item) => item.industryl1 !== 'OTHERS')
                        ?.slice(Math.ceil(industries?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('industry');
                              suggestSearch(item.industryl1,'industry');
                            }}
                          >
                            {item.industryl1}
                          </Dropdown.Item>
                        ))}
                    </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}

                  <Image
                    src={bgDesign}
                    alt='flash'
                    className='back-design d-none d-lg-block'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show}>
          <SearchFilter setShow={setShow} />
        </Modal>
      </SearchDiv>
      <Footer />

      <BottomBar />
    </>
  );
});

export default Search;
